@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap);

.loadingPage {
  overflow: hidden;
  display: flex;
  min-height: 300px;
  min-width: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loader {
  border-radius: 50%;
  position: relative;
  margin: 50px;
  display: inline-block;
  height: 0px;
  width: 0px;
}

.loader span {
  position: absolute;
  display: block;
  background: #ddd;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: -20px;
  perspective: 100000px;
}
.loader span:nth-child(1) {
  left: 60px;
  animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
  animation-delay: 0s;
  background: #ff756f;
}
.loader span:nth-child(2) {
  left: 20px;
  animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
  animation-delay: 0.2s;
  background: #ffde6f;
}
.loader span:nth-child(3) {
  left: -20px;
  animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
  animation-delay: 0.4s;
  background: #01de6f;
}
.loader span:nth-child(4) {
  left: -60px;
  animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
  animation-delay: 0.6s;
  background: #6f75ff;
}

@keyframes bounce2 {
  0%,
  75%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-30px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

.enterImg {
  width: 180px;
  margin-top: 100px;
}

.showDangerBody {
  background: black;
  display: flex;
  justify-content: center;
}

.enterBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enterBtn {
  background-color: white;
  color: black;
  font-size: 20px;
  border-radius: 10px;
  font-weight: bold;
  width: 200px;
  padding: 8px;
}

.nightmare {
  height: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}

.nightmareBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nightmareRule {
  color: #c8102e;
}

.nightmareStep1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nightmareText {
  font-size: 20px;
  color: white;
  margin-top: 10px;
  letter-spacing: 2px;
  max-width: 800px;
  font-family: "Permanent Marker";
}

.nightmareHeader {
  font-size: 24px;
  color: white;
  margin-top: 15px;
  letter-spacing: 2px;
  font-family: "Permanent Marker";
  margin-bottom: 50px;
}

.nightmareFirst1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nightmareNFT {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  border: 2px solid white;
  margin-right: 60px;
  position: relative;
}

.red {
  color: #c8102e;
}

.nightmareBtn {
  color: black !important;
  background-color: white !important;
  margin: 15px;
  min-width: 100px;
  font-weight: bold;
  font-size: 18px !important;
  font-family: "Permanent Marker";
}

.nightmareBtn:hover {
  background-color: lightgray !important;
}

.nightmareBtn:disabled {
  cursor: not-allowed;
  pointer-events: unset !important;
  color: black !important;
  background-color: darkgray;
  opacity: 0.6;
}

.s3Rarities {
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  margin-top: 50px;
}
.s3RareRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sr3RowText > span {
  text-decoration: line-through;
  color: lightgray;
}

.sr3Green {
  color: lime;
}

.nightmareFinish {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nmSac {
  color: #c8102e !important;
  width: 200px;
}

.sr3Increase {
  font-size: 16px !important;
  color: lime !important;
  margin-top: 20px;
}


.sr3Text {
  text-align: center;
  color: rgb(105 67 244);
}

.nightmareFirst .nightmareNFT {
  margin: 10px !important;
}

.nightmareNFTBox {
  border: 2px solid lime;
  padding: 10px;
  border-radius: 10px;
}

.nftFailed {
  border: 2px solid #c8102e;
}

.nightmareNFTEnd {
  margin-left: 60px !important;
}

.sr3RowText {
  margin: 0;
}

.nightmareBtn > span {
  color: #c8102e;
}

.nightmareButtons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 400px;
}
.lost {
  letter-spacing: 2px;
  color: white;
  margin: 20px auto;
  width: 100%;
  font-size: 100px;
  font-family: "Permanent Marker";
  animation: bloody 4s ease infinite alternate;
  -webkit-animation: bloody 4s ease infinite alternate;
  -moz-animation: bloody 4s ease infinite alternate;
  -o-animation: bloody 4s ease infinite alternate;
  color: white;
  padding-top: 100px;
  margin-bottom: 0;
  padding-bottom: 0;
  margin: auto;
}
@keyframes bloody {
  0% {
    text-shadow: 0px 1px 3px #c8102e;
    letter-spacing: -0.025em;
  }
  100% {
    text-shadow: 0px 5px 3px #c8102e, 0px 10px 3px #c8102e, 0px 15px 3px #c8102e,
      0px 20px 3px #c8102e, 0px 25px 3px #c8102e, 0px 30px 3px #c8102e,
      0px 35px 3px #c8102e, 0px 40px 3px #c8102e, 0px 45px 3px #c8102e;
  }
}
.note {
  color: #c8102e;
  font-size: 80px;
  margin: 0 auto;
  font-family: "Permanent Marker";
  animation: grow 5s ease infinite alternate;
  -webkit-animation: grow 5s ease infinite alternate;
  -moz-animation: grow 5s ease infinite alternate;
  -o-animation: grow 5s ease infinite alternate;
}
@keyframes grow {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1.25);
  }
}
.container {
  position: relative;
  margin: 25px auto;
  height: 400px;
  width: 350px;
}
.blooddrop {
  position: relative;
  width: 11px;
  height: 11px;
  top: 0px;
  margin: 0;
  background-color: #c8102e;
  border-radius: 50%;
  animation: drip 3s cubic-bezier(1, 0, 0.91, 0.19) infinite;
  -webkit-animation: drip 3s cubic-bezier(1, 0, 0.91, 0.19) infinite;
  -moz-animation: drip 3s cubic-bezier(1, 0, 0.91, 0.19) infinite;
  -o-animation: drip 3s cubic-bezier(1, 0, 0.91, 0.19) infinite;
}
.blooddrop:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 23px solid #c8102e;
  top: -20px;
  left: 1px;
}
.blooddrop:nth-of-type(1) {
  position: absolute;
  left: 20px;
  top: 0px;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(2) {
  position: absolute;
  left: 53px;
  top: 0px;
  animation-delay: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(3) {
  position: absolute;
  left: 80px;
  top: 0px;
  animation-delay: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(4) {
  position: absolute;
  left: 15px;
  top: 0px;
  animation-delay: 5.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(5) {
  position: absolute;
  left: 130px;
  top: 0px;
  animation-delay: 4.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(6) {
  position: absolute;
  left: 172px;
  top: 0px;
  animation-delay: 3.2s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(7) {
  position: absolute;
  left: 222px;
  top: 0px;
  animation-delay: 4.8s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(8) {
  position: absolute;
  left: 245px;
  top: 0px;
  animation-delay: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(9) {
  position: absolute;
  left: 280px;
  top: 0px;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
.blooddrop:nth-of-type(10) {
  position: absolute;
  left: 300px;
  top: 65px;
  animation-delay: 2.2s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
}
@keyframes drip {
  0% {
    top: 70px;
  }
  100% {
    top: 600px;
  }
}


.btn {
  font-size: 14px;
  padding: 6px 12px;
  margin-bottom: 0;

  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}
.btn:focus,
.btn:active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}
.btn:active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

/* default
---------------------------- */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
